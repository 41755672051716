import styled from '@emotion/styled';

type OverlayProps = {
  displayOverlay: any;
};

const Overlay = styled.div<OverlayProps>`
  position: fixed; /* Sit on top of the page content */
  display: ${p => (p.displayOverlay ? 'block' : 'none')}; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 5; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
`;

export default Overlay;
